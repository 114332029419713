import React, { Component } from "react";
import ContactForm from "./ContactForm";
import "./ContactTwo.css"; 

class ContactTwo extends Component {
    render(){
        return(
            <div className="contact-form--1" style={{}}>
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <h2 className="title" style={{ fontFamily: 'Brice-Black', color: '#ef5474', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.6)'}}></h2>
                                {/* <p className="description" style={{ fontFamily: 'Brice-Light', color: 'black'}}>A staff member will get back to you within one business day.</p> */}
                            </div>
                            <div className="form-wrapper">
                                <ContactForm />
                            </div>
                        </div>
                        <div id="showOrNot" className="col-lg-6 order-1 order-lg-2">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <img src="/assets/images/blog/readyToFloat.jpg" alt="trydo"/>
                                {/* trydo/build/assets/images/blog/readyToFloat.jpg */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactTwo;